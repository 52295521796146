<template>
    <p>Selecteer bij dit aspect één of enkele onderstaande acties. Kies de acties die kansrijk en haalbaar zijn en die passen bij jouw situatie/team.</p>

    <div class="action-items-wrapper">
        <h3 class="h2 text-white">Actiepunten</h3>



        <ul class="list-unstyled">
            
            <li v-for="actionItem in actionItems" v-bind:key="actionItem" >
                <CheckboxLabel v-bind:actionItem="actionItem" @change-action-point="changeActionPoints(actionItem)" />
            </li>
        </ul>
    </div>

    <div class="text-right mb-5" v-if="hasSelectedActionPoints">
        <router-link to="/werkplan/" class="btn btn-secondary">Gekozen actiepunten opnemen in werkplan</router-link>
    </div>
</template>

<script>
    import CheckboxLabel from "./CheckboxLabel";

    export default {
        name: 'ActionItems',
        components: {
            CheckboxLabel
        },
        props: {
            actionItems: Array,
            aspect: Object
        },
        methods: {
            changeActionPoints(actionItem) {
                if (actionItem.selected === true) {
                    actionItem.selected = false;
                    this.$props.aspect.unsetActionActionItem(actionItem);
                } else {
                    actionItem.selected = true;
                    this.$props.aspect.setActionActionItem(actionItem);
                }
            },

        },
        computed: {
            hasSelectedActionPoints() {
                for (const actionItemKey in this.actionItems) {
                    const actionItem = this.actionItems[actionItemKey];
                    if (actionItem.selected == true) {
                        return true;
                    }
                }

                return false;
            }
        },
    }
</script>