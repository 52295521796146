<template>
    <div>
        <h2 class="text-secondary">Aspect: {{ aspect.title}}</h2>

        <div class="tabs-wrapper">

            <ul class="nav-tabs">
                <TabnavigationTab v-for="intervention in aspect.interventions" :label="getNavTitle(intervention)" :isActive="isActiveIntervention(intervention)" :key="intervention" @choose="chooseIntervention(intervention)" />
            </ul>

        </div>


        <div class="mb-5">
            <ActionItems :actionItems="intervention.actionPoints" :aspect="aspect" />
        </div>
        
        <div class="document-cards mt-7" v-if="hasKnowledgeItems">
            <h3 class="text-secondary">Aan de slag!</h3>
            <p>Ter ondersteuning bij de acties vind je hieronder een greep uit praktijkvoorbeelden, artikelen, instrumenten, filmpjes en tips.</p>

            <DocumentCards :documents="intervention.knowledgebaseItems" />
        </div>

    </div>
</template>

<script>
    import api from '@/data/api'
    import DocumentCards from "@/components/DocumentCards";
    import ActionItems from "@/components/ActionItems";
    import TabnavigationTab from "@/components/TabnavigationTab";
    import AspectModel from '@/models/AspectModel';

    export default {
        name: 'Home',
        components: {
            ActionItems,
            DocumentCards,
            TabnavigationTab
        },
        data() {
            return {
                theme: {},
                intervention: {},
                aspect: new AspectModel([])
            }
        },
        methods: {
            getNavTitle(intervention) {
                return this.titles[intervention.title];
            },
            isActiveIntervention(intervention) {
                return this.$route.params.interventie === intervention.slug;
            },
            chooseIntervention(intervention) {
                this.$router.push({ 
                    name: 'interventie-thema-detail',
                    params: { 
                        thema: this.theme.slug, 
                        aspect: this.aspect.slug, 
                        interventie: intervention.slug 
                    }
                });
            }
        },
        computed: {
            titles() {
                let prevLevel = null;
                const titles = [];
                for (const level of this.aspect.levels) {
                    if (prevLevel) {
                        titles[prevLevel.title] = prevLevel.title + ' -> ' + level.title;
                    }
                    prevLevel = level;
                }

                return titles;
            },
            hasKnowledgeItems() {
                if (this.intervention.knowledgebaseItems && this.intervention.knowledgebaseItems.length > 0) {
                    return true;
                }

                return false;
            }            
        },
        created() {
            this.theme = this.$store.getters.getThemeBySlug(this.$route.params.thema);
        },
        mounted() {
            api.fetchAspect(this.$route.params.aspect).then(response => {
                this.aspect = response;

                const breadcrumbs = [
                    {
                        'label': 'Home',
                        'path': '/'
                    },
                    {
                        'label': 'Acties',
                        'path': '/interventies/'
                    },
                    {
                        'label': this.theme.pageTitle,
                        'path': '/interventies/' + this.theme.slug + '/'
                    },
                    {
                        'label': this.aspect.title,
                    },
                ];

                this.$store.commit('SET_BREADCRUMBS', {breadcrumbs});
            });

            api.fetchIntervention(this.$route.params.interventie).then(response => {
                this.intervention = response;
            });
        },
        updated() {
            if (this.intervention.slug != this.$route.params.interventie) {
                api.fetchIntervention(this.$route.params.interventie).then(response => {
                    this.intervention = response;
                });
            }

            if (
                typeof this.aspect == 'object' && 
                typeof this.intervention == 'object' &&
                typeof this.intervention.actionPoints == 'object'
            ) {
                for (const actionItemKey in this.intervention.actionPoints) {
                    if (this.aspect.isActionItemChosen(this.intervention.actionPoints[actionItemKey].text) !== false) {
                        this.intervention.actionPoints[actionItemKey].selected = true;
                    } else {
                        this.intervention.actionPoints[actionItemKey].selected = false;
                    }
                }

                this.initiatedActionItems = true;
            }
        }
    }
</script>
